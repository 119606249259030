import { combineReducers } from "redux";
import user from "./reducers/User";
import sidebar from "./reducers/sidebar";

const RootReducer = combineReducers({
  user,
  sidebar,
});

export default RootReducer;
