import { getLocalStorage } from "../../utils/Utils";
import { USER_DATA, LOADING, } from "../Types";


const InitialState = {
	userData: getLocalStorage("userData") || "",
	isLoading: false,
};


export default (state = InitialState, { type, payload }) => {
	switch (type) {
	  case USER_DATA:
		return { ...state, userData: payload };
	  case LOADING:
		return { ...state, isLoading: payload };
	  default:
		return { ...state };
	}
  };


// export const loginReducers = (state = loginInitialState, type, payload) => {
// 	switch (type) {
// 		case "LOGIN":
// 			return {
// 				...state,
// 				login: payload,
// 			};

// 		case "USER_DATA":
// 			return { ...state, userData: payload };

// 		case "LOADING":
// 			return { ...state, isLoading: payload };

// 		default:
// 			return state;
// 	}
// };
