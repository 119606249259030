import React, { Component, useContext } from "react";
import { setUserData, toggler } from "../redux/actions/User";
import { history } from "../utils/Utils";
import SuperAdmin from "../assets/images/superadmin.webp";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useSelector } from "react-redux";
import store from "../redux/Store";

function UserBar() {
  const navigate = useNavigate();
  const sidebarOpen = useSelector((store) => store.sidebar["sidebarOpen"]);

  const handleLogout = async (e) => {
    e.preventDefault();
    setUserData();
    // toast.success("LoggedOut Successfully");
    navigate("/");
  };
  const handleChangePassword = () => {
    store.getState().user.userData.userRole === "superAdmin"
      ? navigate("/app/super/change-password")
      : navigate("/app/change-password");
  };
  const openProfile = () => {
    history.replace("/app/profile");
  };

  const onChangePassword = () => {
    history.push("/app/change-password");
  };

  const onUpdateVersion = () => {
    history.push("/app/update-version");
  };

  return (
    <div className="user-bar">
      <div className="left">
        <GiHamburgerMenu
          className="handburger"
          onClick={() => {
            toggler(!sidebarOpen);
          }}
        />
      </div>
      <div className="right">
        <div className="dropdown">
          <a
            className=" dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={SuperAdmin}
              alt="superadminimage"
              className="super-account"
            />
          </a>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <span
                className="dropdown-item"
                id="chnagePassword"
                onClick={handleChangePassword}
              >
                Change Password
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                id="logout"
                onClick={handleLogout}
              >
                Logout
              </span>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="right">
        <nav className="navbar navbar-dark navbar-expand-sm">
          <button
            className="navbar-toggler btn btn-secondary dropdown-toggle"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-list-4"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar-list-4">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle "
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={SuperAdmin}
                    alt="superadminimage"
                    className="super-account"
                  />
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <span
                    className="dropdown-item"
                    id="chnagePassword"
                    onClick={handleChangePassword}
                  >
                    Change Password
                  </span>
                  <span
                    className="dropdown-item"
                    id="logout"
                    onClick={handleLogout}
                  >
                    Logout
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div> */}
    </div>
  );
}

export default UserBar;
