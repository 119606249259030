import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import UserBar from "../components/UserBar";

function AppLayout({ children }) {
  const [show, setshow] = useState(false);
  const toggle = () => {
    setshow(!show);
  };

  var pageUrl = window.location.href.split('/').pop()

  return (
    <div className="row app-layout">
      <Sidebar show={show} toggle={toggle} />
      <div className="app-content">
        <UserBar />
        <div className="content">
          <span id="app-view" />
          <div className="content-body">{children}</div>
          {
            pageUrl && pageUrl != 'addproject' &&
            <div className="footer">
            Copyright © 2022 Inline Group. All Rights Reserved.
          </div>
          }
         
        </div>
      
      </div>
    </div>

    // <div className="dashboard">
    //    <div className="wrapper">
    //       <div className="sidebar-container">
    //          <div className="sidebar-group">
    //             <div className="nav-container">
    //                <Link
    //                   to="/dashboard"
    //                   onClick={() => setActiveLink("surveyers")}
    //                   className={`${activeLink === "surveyers" ? "active" : ""} dashboard-link`}>
    //                   <i className="fa-solid fa-list"></i>
    //                   Surveyers
    //                </Link>
    //                <Link
    //                   to="/projects"
    //                   onClick={() => setActiveLink("projects")}
    //                   className={`${activeLink === "projects" ? "active" : ""} dashboard-link`}>
    //                   <i className="fa-solid fa-book"></i>
    //                   Projects
    //                </Link>
    //                <Link
    //                   to="/suggestions"
    //                   onClick={() => setActiveLink("suggestions")}
    //                   className={`${activeLink === "suggestions" ? "active" : ""
    //                      } dashboard-link`}>
    //                   <i className="fa-solid fa-lightbulb"></i>
    //                   Suggestions
    //                </Link>
    //             </div>
    //          </div>
    //          <div className="logout-btn-block">
    //             <button className="logout-btn" onClick={() => navigate("/")}>
    //                Logout
    //             </button>
    //          </div>
    //       </div>

    //       <div className="admin-info-container">
    //          <div className="logo-box">INLINEGROUPINC</div>

    //          <div className="admin-info-section">{children}</div>
    //          <Footer dashRendered={dashRendered} />
    //       </div>
    //    </div>
    // </div>

    // <div className="row app-layout">
    //   <SideBar show={show} toggle={toggle} t={t} />
    //   <div className="app-content">
    //     <UserBar t={t} />
    //     <div className="content">
    //       <span id="app-view" />
    //       <div className="content-body">{children}</div>
    //       <div className="footer">Copyright © 2021 B&T. All Rights Reserved.</div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default AppLayout;
