import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Button, FormGroup } from "reactstrap";
import logo from "../../assets/images/MicrosoftTeams-image.png";
import { FormikInput } from "../../components/FormikFields";
import OtpInput from "../../components/OtpInput";
import { setUserData } from "../../redux/actions/User";
import apiClient from "../../utils/ApiClient";
import {
  loginSchema,
  otpSchema,
  signupSchema,
} from "../../utils/ValidationSchema";
import AuthLogo from "../../assets/images/auth.jpeg";

function AppUserLogin() {
  const [view, setView] = useState("loginAppUser");
  const [email, setEmail] = useState("");
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    if (view === "verify") {
      let interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            return prevTimer;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [view]);

  const navigate = useNavigate();
  function handleSignup({ email, phoneNumber, firstName, lastName, password, userRawId }) {
    var inputObj = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
      phoneNumber: phoneNumber,
      userRawId: userRawId
    };

    apiClient.post("admin/users/signup", inputObj).then(({ status, data }) => {
      if (status === 200) {
        setView("verify");
        setEmail(email);
      }
    });
  }

  /* verify otp */
  function handleVerify(values) {
    const { otp1, otp2, otp3, otp4, otp5 } = values;
    const value = otp1 + otp2 + otp3 + otp4 + otp5;
    var obj = {
      email: email,
      otp: parseInt(value),
    };

    apiClient.post("admin/users/email-verify", obj).then(({ status, data }) => {
      if (status === 200) {
        toast.success("Email verified successfully");
        setView("done");
      } else {
      }
    });
  }

  // resend-otp-email
  function handleResend() {
    var obj = {
      email: email,
      userRole: "admin",
    };
    apiClient
      .post("admin/users/resend-otp-email", obj)
      .then(({ status, data }) => {
        if (status === 200) {
          toast.success(`Code Sent To ${obj.email}`);
          setView("verify");
          setTimer(120);
        }
      });
  }

  /* login */
  function handleLogin({ email, password }) {
    var obj = {
      email: email,
      password: password,
    };

    apiClient.post("admin/users/login", obj).then(({ status, data }) => {
      if (status === 200) {
        if (data.data.userRole === "superAdmin") {
          localStorage.setItem("superAdmin", data.data.email);
          setUserData(data.data, data.data["token"]);
          toast.success("Login successfully..!");
          navigate("/app/super/admin");
        } else if (data.data.userRole === "admin") {
          setUserData(data.data, data.data["token"]);
          toast.success("Login successfully..!");
          navigate("/app/surveyors");
        }
      }
    });
  }

   /* login App User */
   function handleLoginAppUser({ email, password }) {
    console.log("handleLoginAppUser")
    var obj = {
      email: email,
      password: password,
    };

    apiClient.post("users/surveyors/signInAppUser", obj).then(({ status, data }) => {
      if (status === 200) {
        if (data.data.userRole === "surveyor") {
          localStorage.setItem("surveyorEmail", data.data.email);
          localStorage.setItem("appUserId", data.data._id);
          setUserData(data.data, data.data["token"]);
          toast.success("Login successfully..!");
          navigate("/home");
        } 
      }
    });
  }

  function titleCase(str) {
    var splitStr = str && str.toLowerCase().split(" ");
    if (splitStr) {
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    } else {
      return str;
    }
  }

  const redirectLogin = () => {
    setView("login");
  };
  /* customised view */
  const renderItems = () => {
    switch (view) {
      case "signup":
        return (
          <>
            <h4>Sign up</h4>
            <Formik
              enableReinitialize
              initialValues={{
                email: "",
                password: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                userRawId: ""
              }}
              validationSchema={signupSchema}
              onSubmit={handleSignup}
            >
              {({
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                values,
              }) => (
                <Form className="mt-5">
                  <FormGroup className="mb-4">
                    <FormikInput
                      placeholder="First Name"
                      name="firstName" // error={touched.email && errors.email}
                      value={titleCase(values.firstName)}
                      error={touched.firstName && errors.firstName}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <FormikInput
                      placeholder="Last Name"
                      name="lastName" // error={touched.email && errors.email}
                      value={titleCase(values.lastName)}
                      error={touched.lastName && errors.lastName}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>

                  <FormGroup className="mb-4">
                    <FormikInput
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      error={touched.email && errors.email}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <FormikInput
                      placeholder="Phone number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      error={touched.phoneNumber && errors.phoneNumber}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <FormikInput
                      placeholder="Password"
                      name="password"
                      type="password"
                      error={touched.password && errors.password}
                      value={values.password}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      iconClassName={touched.password && errors.password ? "password-icon-error" : "password-icon"}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <FormikInput
                      placeholder="User id"
                      name="userRawId"
                      value={values.userRawId}
                      error={touched.userRawId && errors.userRawId}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <Button className="login-button w-100 mt-4" type="submit">
                    Signup
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        );

      case "verify":
        return (
          <>
            <h4>Email Verification</h4>
            <p className="mt-4">We have sent a verification code to your email.</p>
            <Formik
              // enableReinitialize={true}
              validationSchema={otpSchema}
              initialValues={{
                otp1: "",
                otp2: "",
                otp3: "",
                otp4: "",
                otp5: "",
                // disable: true,
              }}
              onSubmit={(values, { resetForm }) => {
                handleVerify(values);
                resetForm({ values: "" });
              }}
            >
              {({
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                values,
              }) => (
                <Form className="mt-5">
                  <FormGroup className="mb-4 output-group">
                    <OtpInput
                      className="otpInput"
                      error={touched && errors}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      values={values}
                    />
                  </FormGroup>
                  <FormGroup className="mx-4">
                    <Button className="login-button w-100 my-3" type="submit">
                      Submit
                    </Button>
                  </FormGroup>
                  <FormGroup className="ml-auto">
                    <p className="">
                      Didn't received code? &nbsp;
                      <button
                        className={timer === 0 ? "resend-active" : "resend"}
                        color="blue"
                        disabled={timer === 0 ? false : true}
                        onClick={handleResend}
                      >
                        {timer === 0
                          ? "Resend code"
                          : `Resend code ( ${timer}  seconds )`}
                      </button>
                    </p>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </>
        );

      case "login":
        return (
          <>
            <h4>Login</h4>
            <Formik
              enableReinitialize
              validationSchema={loginSchema}
              initialValues={{ email: "", password: "" }}
              onSubmit={handleLogin}
            >
              {({
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                values,
              }) => (
                <Form className="mt-5">
                  <FormGroup className="mb-4 ">
                    <FormikInput
                      placeholder="Email"
                      name="email" // error={touched.email && errors.email}
                      value={values.email}
                      error={touched.email && errors.email}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <FormikInput
                      placeholder="Password"
                      name="password" // error={touched.email && errors.email}
                      value={values.password}
                      type="password"
                      error={touched.password && errors.password}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      iconClassName="password-icon"
                    />
                  </FormGroup>

                  <div className="forgot-pass-block">
                    <Link to="/forgot-password" className="forgot-pass-link">
                      Forgot Password?
                    </Link>
                  </div>
                  <Button className="login-button w-100 mt-4" type="submit">
                    Login
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        );
        case "loginAppUser":
        return (
          <>
            <h4>Login App User</h4>
            <Formik
              enableReinitialize
              validationSchema={loginSchema}
              initialValues={{ email: "", password: "" }}
              onSubmit={handleLoginAppUser}
            >
              {({
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                values,
              }) => (
                <Form className="mt-5">
                  <FormGroup className="mb-4 ">
                    <FormikInput
                      placeholder="Email"
                      name="email" // error={touched.email && errors.email}
                      value={values.email}
                      error={touched.email && errors.email}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <FormikInput
                      placeholder="Password"
                      name="password" // error={touched.email && errors.email}
                      value={values.password}
                      type="password"
                      error={touched.password && errors.password}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      iconClassName="password-icon"
                    />
                  </FormGroup>

                  {/* <div className="forgot-pass-block">
                    <Link to="/forgot-password" className="forgot-pass-link">
                      Forgot Password?
                    </Link>
                  </div> */}
                  <Button className="login-button w-100 mt-4" type="submit">
                    Login
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        );
      case "done":
        return (
          <>
            <h4>Verification Successful</h4>
            <p className="mt-5">
              Once our administrators approve your account we will notify you by the email provided.
            </p>
            <Button className="login-button w-100 mt-4" onClick={redirectLogin}>
              Login
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="signup">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-lg-6">
          <img src={AuthLogo} alt="logo" id="logo" />
        </div>
        <div className={view === "verify" ? "col-form-resend" : "col-form p-3"}>
          <div
            className="logo-block"
            style={{
              textAlign: "center",
              marginBottom: "3rem",
            }}
          >
            <img
              style={{ height: "47px", width: "75px" }}
              src={logo}
              alt="logo"
            />
          </div>
          {renderItems()}
          {view === "login" && (
            <p className="mt-5 cursor-pointer">
              Create an account as Admin ?{" "}
              <strong onClick={() => setView("signup")}>Signup</strong>
            </p>
          )}
          {view === "signup" && (
            <p className="mt-5 cursor-pointer">
              Already have a account ?{" "}
              <strong onClick={() => setView("login")}>Login</strong>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default AppUserLogin;
