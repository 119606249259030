import React, { useState } from "react";
import { Nav } from "reactstrap";
import { getMenu } from "../utils/Routes";
import MenuNavItem from "./MenuNavItem";
import Logo from "../assets/images/logo_white.png";
import { useSelector } from "react-redux";
import { ImCross } from "react-icons/im";
import { toggler } from "../redux/actions/User";
function Sidebar({ show, toggle }) {
  const sidebarOpen = useSelector((store) => store.sidebar["sidebarOpen"]);
const [isOpen,setIsOpen] = useState(false);
const [routeName,setRouteName] = useState(false);


  const setToggle = (name) =>{
    setRouteName(name)
    setIsOpen(!isOpen)
  }


  return (
    <div className={`sidebar ${show && `show`} ${sidebarOpen && `sidebar1`}`}>
      {sidebarOpen && (
        <ImCross
          className="cross"
          onClick={() => {
            toggler(!sidebarOpen);
          }}
        />
      )}
      <Nav vertical className="pt-4">
      {console.log('children=getMenu',getMenu())}

        <img src={Logo} alt="logo" className="logo" />
        {getMenu().map((item, i) => {
          // console.log('getMenu=',item)
          return(
          <MenuNavItem
            key={i}
            to={item.to}
            children={item?.submenu}
            toggle={toggle}
            setToggle={setToggle}
            name={item.name}
            icon={item.Icon}
            isOpen={isOpen}
            route={item.route}
            routeName={routeName}
          ></MenuNavItem>
        )
})}
      </Nav>
    </div>
  );
}

export default Sidebar;
