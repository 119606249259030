import { Input, Label } from "reactstrap";
import React, { forwardRef, useState } from "react";
import Select from "react-select";
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'

export const FormikInput = (props) => {
  const handleChange = (e) => {
    !props.disabled &&
      props.onChange &&
      props.onChange(
        props.name,
        props.type === "number"
          ? parseFloat(e.target.value) || ""
          : e.target.value
      );
  };

  const handleBlur = () => {
    props.onBlur && props.onBlur(props.name, true);
  };

  const [showText, setToggle] = useState(false);

  const toggle = () => setToggle(!showText);

  return (
    <div className="input-wrapper">
      {props.label && <Label for="exampleEmail">{props.label}</Label>}

      <Input
        className={props.type === 'password' ? 'password-field' : props.className || ""}
        rows={props.multiline && (props.rows || 50)}
        placeholder={props.placeholder}
        required={props.required ? true : false}
        multiline={props.multiline}
        type={
          props.type === "password"
            ? showText
              ? "text"
              : "password"
            : props.type || "text"
        }
        error={Boolean(props.error)}
        variant={props.variant}
        style={{
          ...(props.disabled && { pointerEvents: "none" }),
          color: "black",
          fontWeight: 900,
        }}
        label={props.label}
        value={props.value}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={300}
      />

      {
        props.type === "password" &&
        <span className={props.iconClassName} onClick={toggle}>
          {showText === true ? <AiOutlineEyeInvisible size={25} /> : <AiOutlineEye size={25} />}
        </span>
      }
      <div className="invalid-feedback text-center">{props.error}</div>
    </div>
  );
};

export const FormikSelect = (props) => {
  const { options, name, label,value, disabled} = props;

  const handleChange = (e) => {
    console.log(e);
    props.onChange && props.onChange(props.name, e.value);
  };

  const handleBlur = () => {
    props.onBlur && props.onBlur(props.name, true);
  };
  let selected = options.find((item)=> item?.value == value)
  console.log('selected',selected)
  return (
    <>
      {label && <Label for="exampleEmail">{label}</Label>}
      <Select
      disabled={disabled}
        className="basic-single"
        classNamePrefix="select"
        onChange={handleChange}
        handleBlur={handleBlur}
        name={name}
        defaultValue={selected}
        value={selected}
        options={options}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: "orangered",
            primary25: "#6c757d",
            primary: "grey",
          },
        })}
      />
    </>
  );
};
