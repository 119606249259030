import axios from "axios";
import toast from 'react-hot-toast'

import { loading, setUserData } from "../redux/actions/User";
import { baseURL } from "./Config";
import { getLocalStorage, history } from "./Utils";
console.log("baseURLL",baseURL)

const apiClient = axios.create({
  baseURL
});


apiClient.interceptors.request.use(
  (config) => {
    if (!config.optional) loading(true);
    return {
      ...config,
      headers: {
        authorization: config.headers.authorization || "Bearer " + getLocalStorage("token"),
      }
    };
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => {
    loading(false);
    return response;
  },
  async ({ response }) => {
    if (response && response.status === 401) {
      const { data,  } = response;
      loading(false);
      setUserData().then(() => {
        toast.error(data.message);
        history.push("/login");
      });
      return response;
    } else if (response && response.status === 400) {
      const { data, status } = response;
      loading(false);
      errorsParser(data.error);
      if (!data.error) toast.error(data.message);
      return Promise.resolve(data);
    } else {
      // const { data, status } = response;
      if (response) {
        var { data, status } = response;
      }
      toast.error(response ? data.message : "Request Failed!! Please check your internet connection")
      loading(false);
      return Promise.reject({});
    }
  }
);

const errorsParser = (errors) => {
  if (errors)
    typeof errors === "string"
      ? toast.error(errors)
      : Object.keys(errors).forEach((item) => {
        Array.isArray(errors[item])
          ? errors[item].forEach((item2) => {
            toast.error(item + " " + item2);
          })
          : typeof errors[item] === "object"
            ? errorsParser(errors[item])
            : toast.error(errors[item]);
      });
};

export default apiClient;