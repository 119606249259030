import React, { lazy, useState } from "react";
import { useSelector } from "react-redux";
import AppLayout from "../../layout/AppLayout";
import { Button, Modal } from "reactstrap";
import apiClient from "../../utils/ApiClient";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const Admin = lazy(() => import('../Admin'));
const SuperAdmin = lazy(() => import('../SuperAdmin'));

function Home() {
  const userRole = useSelector(store => store.user['userData']['userRole']);
  const navigate = useNavigate();
  const [appUserDelete, setAppUserDelete] = useState({
    deleteModelOpen: false,
    password: "",
    data: "",
  });

  const renderItems = () => {
    var url = '/app';

    switch (userRole) {
      case "admin":
        return <Admin url={url} />;
      case "superAdmin":
        return <SuperAdmin url={url} />;
      default:
        return null;
    }
  };

  const openDeleteAppUser = (rowData) => {
    setAppUserDelete({
      ...appUserDelete,
      deleteModelOpen: !appUserDelete.deleteModelOpen,
      data: rowData ? rowData._id : "",
    });
  };

  const deleteAppUser = () => {
    const id = localStorage.getItem("appUserId")
    const obj = {
      id: id,
      password: appUserDelete.password,
    };

    apiClient
      .put(`users/surveyors/deleteAppUer`, obj)
      .then(({ status, data }) => {
        if (status === 200) {
          setAppUserDelete({
            ...appUserDelete,
            deleteModelOpen: false,
          });
          toast.success("User has been successfully deleted");
          navigate("/login/appUser");
        };
        
      });
  };

  return (
    <>
      <AppLayout>
        {renderItems()}
        <Button
          onClick={() => openDeleteAppUser()}
          style={{ marginTop: "30px", marginLeft: "350px" }}
        >
          Do you want to delete your account
        </Button>
        <Modal
          isOpen={appUserDelete.deleteModelOpen}
          toggle={() => openDeleteAppUser()}
        >
          <h2 className="text-center mt-2">
            <b>Alert</b>{" "}
          </h2>
          <p className="text-center px-3">
            Do you really want to delete this
            <b style={{ fontSize: 20, margin: 5, color: "red" }}>
            </b>
            account? This will be an irreversible action. Please enter your
            password and click delete to proceed
          </p>
          <div
            className="add-counter"
            style={{ height: "120px", marginTop: "20px" }}
          >
            <div className="fields col-10 mx-auto text-center">
              <input
                type="password"
                className="col-10 p-2"
                name="password"
                placeholder="Enter password"
                id="password"
                onChange={(e) => {
                  setAppUserDelete({
                    ...appUserDelete,
                    password: e.target.value,
                  });
                }}
              />

              <div className="col-12 text-center ">
                <Button
                  type="submit"
                  className="my-4 mx-auto"
                  onClick={() => deleteAppUser()}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </AppLayout>
    </>
  );
}

export default Home;
