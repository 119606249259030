import React, { Component } from "react";
import { NavItem, Nav, Collapse } from "reactstrap";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { withRouter } from "./WithRouter";

class MenuNavItem extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const {
      children,
      to,
      location: { pathname },
    } = this.props;
    console.log('closeExpand=setToggle',this.state.isOpen)

    // this.setState({
    //   isOpen:
    //     pathname === to ||
    //     Boolean(children && children.find((item) => item.to === pathname)),
    // });
  }

  componentDidUpdate(_prevProps, prevState) {

    const {
      children,
      to,
      location: { pathname },
    } = this.props;
    if (prevState === this.state) {
      // this.setState({
      //   isOpen:
      //     pathname === to ||
      //     Boolean(children && children.find((item) => item.to === pathname)),
      // });
    }
  }



  // closeExpand = () => {
  //   this.setState({ isOpen: false });
  // }

  render() {
    const {routeName,route,setToggle,isOpen, name, toggle, children, to, icon ,location: { pathname },} = this.props;
    // const { isOpen } = this.state;
    return children && children.length > 0 ? (
      <>
{console.log('isoPNe',isOpen)
}<NavLink className={routeName == route ? 'active nav-link d-flex': 'nav-link d-flex'} to={to} onClick={()=>{
        setToggle(route)
      }}>
          {/* {console.log('children=menu',children)} */}
          <span className="mt-1">{icon}</span>
          <div className="text">{name}</div>
       
  
        </NavLink>
        <Collapse isOpen={isOpen && routeName == route}>
{/* {        console.log('submenu',children)
} */}
          <Nav vertical>
            {children && children.map((item, i) => {
            return (
              <NavItem className="suMenu" key={i}>
                        {/* <span className="mt-1">{item?.Icon}</span> */}

                <NavLink
                  className="nav-link"
                  to={item.to}
                  onClick={toggle}
                >{item?.name}</NavLink>
              </NavItem>
            )
            })}
          </Nav>
        </Collapse>
      </>
    ) : (
      <NavLink className="nav-link d-flex" to={to} onClick={()=>{
        this.setToggle('off')
        toggle()
      }}>

        <span className="mt-1">{icon}</span>
        {/* <Icon /> */}
        <div className="text">{name}</div>
      </NavLink>
    );
  }
}

export default withRouter(MenuNavItem);
