import store from "../redux/Store";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";
import { GoProject } from "react-icons/go";
import { CgNotes } from "react-icons/cg";
import { BiTrophy } from "react-icons/bi";
import { RiAdminLine } from "react-icons/ri";
export const getMenu = () => {
  var isManager = store.getState().user.userData.isManager;

  /* financial manager */
  var isFm = store.getState().user.userData.isFm;

  switch (store.getState().user.userData.userRole) {
    case "admin":
      if (isManager === true) {
        return [
          {
            name: "App Users",
            // icon: <BsFillJournalBookmarkFill />,
            to: "/app/surveyors",
            Icon: <MdOutlineAdminPanelSettings />,
          },
          {
            name: "Projects",
            to: "/app/projects",
            Icon: <GoProject />,
          },
          {
            name: "Expense Reimbursement",
            to: "/app/expense-reimbursement",
            Icon: <RiAdminLine />,
          },
          {
            name: "Photo of the month",
            to: "/app/photoofthemonth",
            Icon: <BiTrophy />,
          },
          {
            route: "equipment",
            name: "Equipment Tracking System",
            to: "/app/super/manage-equipment",
            Icon: <CgNotes />,
            submenu: [
              {
                route: "equipment",
                name: "Manage Equipment",
                to: "/app/super/manage-equipment",
                Icon: <CgNotes />,
              },
              {
                route: "equipment",
                name: "Track Equipment",
                to: "/app/super/track-equipment",
                Icon: <CgNotes />,
              },
            ],
          },
          {
            route: "fleet",
            name: "Fleet Management System",
            to: "/app/super/fleet-data",
            Icon: <CgNotes />,
            submenu: [
              {
                route: "fleet",
                name: "Manage Fleet Data",
                to: "/app/super/fleet-data",
                Icon: <CgNotes />,
              },
              {
                route: "fleet",
                name: "Fleet Tracking",
                to: "/app/super/fleet-track",
                Icon: <CgNotes />,
              },
            ],
          },
        ];
      } else if (isFm === true) {
        return [
          {
            name: "App Users",
            // icon: <BsFillJournalBookmarkFill />,
            to: "/app/surveyors",
            Icon: <MdOutlineAdminPanelSettings />,
          },
          {
            name: "Projects",
            to: "/app/projects",
            Icon: <GoProject />,
          },
          {
            name: "Expense Reimbursement",
            to: "/app/expense-reimbursement",
            Icon: <RiAdminLine />,
          },
          {
            name: "Photo of the month",
            to: "/app/photoofthemonth",
            Icon: <BiTrophy />,
          },
          // {
          //   name: "Employee of the month",
          //   to: "/app/employee-month",
          //   Icon: <FaUserShield />,
          // },
        ];
      } else {
        return [
          {
            name: "App Users",
            // icon: <BsFillJournalBookmarkFill />,
            to: "/app/surveyors",
            Icon: <MdOutlineAdminPanelSettings />,
          },
          {
            name: "Projects",
            to: "/app/projects",
            Icon: <GoProject />,
          },
          {
            name: "Photo of the month",
            to: "/app/photoofthemonth",
            Icon: <BiTrophy />,
          },
          // {
          //   name: "Employee of the month",
          //   to: "/app/employee-month",
          //   Icon: <FaUserShield />,
          // },
        ];
      }

    case "superAdmin":
      if (isManager === true || isFm === true) {
        return [
          {
            name: "Super-Admins",
            to: "/app/super/superadminlist",
            Icon: <MdOutlineAdminPanelSettings />,
          },
          {
            name: "Admins",
            to: "/app/super/admin",
            Icon: <MdOutlineAdminPanelSettings />,
          },
          {
            name: "App Users",
            to: "/app/super/surveyors",
            Icon: <BsFillJournalBookmarkFill />,
          },
          {
            name: "Projects",
            to: "/app/super/project",
            Icon: <GoProject />,
          },
          {
            name: "Expense Reimbursement",
            to: "/app/super/expense-reimbursement",
            Icon: <RiAdminLine />,
          },
          {
            name: "Photo of the month",
            to: "/app/super/photoofthemonth",
            Icon: <BiTrophy />,
          },
          {
            name: "Employee of the month",
            to: "/app/super/employee-month",
            Icon: <FaUserShield />,
          },
          {
            name: "Suggestions",
            to: "/app/super/Suggestions",
            Icon: <CgNotes />,
          },
          {
            route: "equipment",
            name: "Equipment Tracking System",
            to: "/app/super/manage-equipment",
            Icon: <CgNotes />,
            submenu: [
              {
                route: "equipment",
                name: "Manage Equipment",
                to: "/app/super/manage-equipment",
                Icon: <CgNotes />,
              },
              {
                route: "equipment",
                name: "Track Equipment",
                to: "/app/super/track-equipment",
                Icon: <CgNotes />,
              },
            ],
          },
          {
            route: "fleet",
            name: "Fleet Management System",
            to: "/app/super/fleet-data",
            Icon: <CgNotes />,
            submenu: [
              {
                route: "fleet",
                name: "Manage Fleet Data",
                to: "/app/super/fleet-data",
                Icon: <CgNotes />,
              },
              {
                route: "fleet",
                name: "Fleet Tracking",
                to: "/app/super/fleet-track",
                Icon: <CgNotes />,
              },
            ],
          },
        ];
      } else {
        return [
          {
            name: "Super-Admins",
            to: "/app/super/superadminlist",
            Icon: <MdOutlineAdminPanelSettings />,
          },
          {
            name: "Admins",
            to: "/app/super/admin",
            Icon: <MdOutlineAdminPanelSettings />,
          },
          {
            name: "App Users",
            to: "/app/super/surveyors",
            Icon: <BsFillJournalBookmarkFill />,
          },
          {
            name: "Projects",
            to: "/app/super/project",
            Icon: <GoProject />,
          },
          {
            name: "Suggestions",
            to: "/app/super/Suggestions",
            Icon: <CgNotes />,
          },
          {
            name: "Photo of the month",
            to: "/app/super/photoofthemonth",
            Icon: <BiTrophy />,
          },
          {
            name: "Employee of the month",
            to: "/app/super/employee-month",
            Icon: <FaUserShield />,
          },
        ];
      }
    default:
      return [];
  }
};
