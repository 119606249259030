import CryptoJS from "crypto-js";
import { createBrowserHistory } from "history";
import { isNullOrUndefined } from "util";

export const history = createBrowserHistory();

const encyrpt = (value) =>
  process.env.NODE_ENV === "development"
    ? value
    : CryptoJS.AES.encrypt(value, process.env.REACT_APP_CRYPT_SALT).toString();

const decyrpt = (value) =>
  process.env.NODE_ENV === "development"
    ? value
    : CryptoJS.AES.decrypt(value, process.env.REACT_APP_CRYPT_SALT).toString(
        CryptoJS.enc.Utf8
      );

export const setLocalStorage = (key, value) => {
  if (isNullOrUndefined(value) || value === "") localStorage.removeItem(key);
  else localStorage.setItem(key, encyrpt(JSON.stringify(value)));
};

export const getLocalStorage = (key) => {
  try {
    return (
      decyrpt(localStorage.getItem(key)) &&
      JSON.parse(decyrpt(localStorage.getItem(key)))
    );
  } catch (e) {
    if (["userData", "token"].includes(key)) clearLocalStorage();
    else localStorage.removeItem(key);
  }
};

export const setSessionStorage = (key, value) => {
  if (isNullOrUndefined(value) || value === "") sessionStorage.removeItem(key);
  else sessionStorage.setItem(key, encyrpt(JSON.stringify(value)));
};

export const getSessionStorage = (key) => {
  try {
    return (
      decyrpt(sessionStorage.getItem(key)) &&
      JSON.parse(decyrpt(sessionStorage.getItem(key)))
    );
  } catch (e) {
    sessionStorage.removeItem(key);
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
};
