import React, { Suspense, lazy } from "react";
import "./assets/scss/styles.scss";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import AppUserLogin from "./pages/AdminAuth/AppUserLogin";
import Home from "./pages/Dashboard/Home";
const AppDashboard = lazy(() => import('./pages/Dashboard/AppDashboard'));
const PublicApp = lazy(() => import('./pages'));



function App() {
  const userData = useSelector((store) => store.user["userData"]);
  const isLoading = useSelector((store) => store.user["isLoading"]);

  return (
    <div>
      <Suspense fallback={<div className="loader"></div>}>
        {isLoading && <div className="loader"></div>}
        <Routes>
          {userData && <Route path="/app/*" element={<AppDashboard />} />}
          <Route path="/*" element={<PublicApp />} />
          <Route path="/login/appuser" element={<AppUserLogin />} />
          <Route path="/home" element={<Home />} />
        </Routes>
        <Toaster position="top-right" reverseOrder={false} />
      </Suspense>
    </div>
  );
}

export default App;
