import { setLocalStorage } from "../../utils/Utils";
import store from "../Store";
import { USER_DATA, LOADING, TOGGLE } from "../Types";

export const login = (bool) => {
  return {
    type: "LOGIN",
    payload: bool,
  };
};

export const setUserData = async (payload = "", token = "") => {
  setLocalStorage("userData", payload);
  setLocalStorage("token", token);
  store.dispatch({ type: USER_DATA, payload });
};

export const loading = (payload) => {
  store.dispatch({ type: LOADING, payload });
};

export const toggler = (payload) => {
  store.dispatch({ type: TOGGLE, payload });
};
