import { TOGGLE } from "../Types";

const InitialState = {
  sidebarOpen: false,
};

export default (state = InitialState, { type, payload }) => {
  switch (type) {
    case TOGGLE:
      return { ...state, sidebarOpen: payload };
    default:
      return { ...state };
  }
};
