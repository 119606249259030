import React from 'react'
import {
   Input,
} from 'reactstrap';


function OtpInput(props) {

   const inputfocus = (elmnt) => {
      if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
         const next = elmnt.target.tabIndex - 2;
         if (next > -1) {

            elmnt.target.form.elements[next].focus()
         }
      }
      else {

         const next = elmnt.target.tabIndex;
         if (next < 5) {
            elmnt.target.form.elements[next].focus()
         }
      }

   }


   const handleChange = (value, e) => {
      !props.disabled &&
         props.onChange &&
         props.onChange(value, props.type === 'number' ? parseFloat(e.target.value) || '' : e.target.value);
   };

   const handleBlur = () => {
      props.onBlur && props.onBlur(props.name, true);
   };

   return (
      <>
         <div className="otpContainer">
            <Input
               name="otp1"
               type={props.text}
               autoComplete="off"
               className={props.className}
               placeholder={props.placeholder}
               value={props.values.otp1}
               onChange={e => handleChange('otp1', e)}
               error={props.error}
               onBlur={handleBlur}
               tabIndex="1" maxLength="1" onKeyUp={e => inputfocus(e)}
            />
            <Input
               name="otp2"
               type={props.text}
               autoComplete="off"
               className={props.className}
               placeholder={props.placeholder}
               value={props.values.otp2}
               onChange={e => handleChange('otp2', e)}
               onBlur={handleBlur}
               tabIndex="2" maxLength="1" onKeyUp={e => inputfocus(e)}
            />
            <Input
               name="otp3"
               type={props.text}
               autoComplete="off"
               className={props.className}
               placeholder={props.placeholder}
               value={props.values.otp3}
               onChange={e => handleChange('otp3', e)}
               onBlur={handleBlur}
               tabIndex="3" maxLength="1" onKeyUp={e => inputfocus(e)}
            />
            <Input
               name="otp4"
               type={props.text}
               autoComplete="off"
               className={props.className}
               placeholder={props.placeholder}
               value={props.values.otp4}
               onChange={e => handleChange('otp4', e)}
               onBlur={handleBlur}
               tabIndex="4" maxLength="1" onKeyUp={e => inputfocus(e)}
            />
            <Input
               name="otp5"
               type={props.text}
               autoComplete="off"
               className={props.className}
               placeholder={props.placeholder}
               value={props.values.otp5}
               onChange={e => handleChange('otp5', e)}
               onBlur={handleBlur}
               tabIndex="5" maxLength="1" onKeyUp={e => inputfocus(e)}
            />
         </div>
         <div className="invalid-feedback text-center">{Object.keys(props.error).length > 0 ? 'Field is required!' : ''}</div>
      </>

   )
}

export default OtpInput