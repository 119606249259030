import * as Yup from "yup";
import {} from "./Utils";
const message = "Field is required!";
const emailMessage = "Invalid email address";
const phoneRegExp = /^[\+\d]?(?:[\d-.\s()]*)$/;
const passwordRegex = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

/* signup */
export const signupSchema = Yup.object().shape({
  email: Yup.string().email(emailMessage).required(message),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required(message),
  firstName: Yup.string()
    .required(message)
    .max(30, "First Name should have maximum 30 character"),
  lastName: Yup.string()
    .required(message)
    .max(30, "Last Name should have maximum 30 character"),
  password: Yup.string()
    .required(message)
    // .min(8, 'password must contain 8 character')
    .matches(
      passwordRegex,
      "Password should be of minimum 8 characters having at least 1 number and 1 special character."
    ),
  userRawId: Yup.string().required(message),
});

/* otp screen */
export const otpSchema = Yup.object().shape({
  otp1: Yup.string().required(message),
  otp2: Yup.string().required(message),
  otp3: Yup.string().required(message),
  otp4: Yup.string().required(message),
  otp5: Yup.string().required(message),
});

/* login schema */
export const loginSchema = Yup.object().shape({
  email: Yup.string().email(emailMessage).required(message),
  password: Yup.string().required(message),
});

/* login schema */
export const projectSchema = Yup.object().shape(
  {
    projectNo: Yup.string().required(message),
    name: Yup.string().required(message),
    client: Yup.string().required(message),
    customerId: Yup.string().required(message),
    contactName: Yup.string().when(
      ["contactTitle", "contactEmail", "contactPhoneNumber"],
      {
        is: (field2, field3, field4) =>
          field2 !== undefined || field3 !== undefined || field4 !== undefined,
        then: (schema) => schema.required(message),
        otherwise: (schema) => schema.notRequired(),
      }
    ),

    contactEmail: Yup.string().when(
      ["contactTitle", "contactName", "contactPhoneNumber"],
      {
        is: (field2, field3, field4) =>
          field2 !== undefined || field3 !== undefined || field4 != undefined,
        then: (schema) => schema.required(message).email(emailMessage),
        otherwise: (schema) => schema.notRequired(),
      }
    ),

    contactPhoneNumber: Yup.string().when(
      ["contactTitle", "contactName", "contactEmail"],
      {
        is: (field2, field3, field4) =>
          field2 !== undefined || field3 !== undefined || field4 != undefined,
        then: (schema) =>
          schema
            .required(message)
            .matches(phoneRegExp, "Phone number is not valid"),
      }
    ),

    contactTitle: Yup.string().when(
      ["contactName", "contactPhoneNumber", "contactEmail"],
      {
        is: (field2, field3, field4) =>
          field2 !== undefined || field3 !== undefined || field4 != undefined,
        then: (schema) => schema.required(message),
      }
    ),

    keyContacts: Yup.array().of(
      Yup.object().shape(
        {
          contactName: Yup.string().required(message),
          contactEmail: Yup.string().required(message).email(emailMessage),
          contactPhoneNumber: Yup.string()
            .required(message)
            .matches(phoneRegExp, "Phone number is not valid"),
          contactTitle: Yup.string().required(message),
        },
        [
          ["contactName", "contactPhoneNumber"],
          ["contactName", "contactEmail"],
          ["contactEmail", "contactPhoneNumber"],
          ["contactEmail", "contactTitle"],
          ["contactTitle", "contactPhoneNumber"],
          ["contactName", "contactTitle"],
        ]
      )
    ),
  },
  [
    ["contactName", "contactPhoneNumber"],
    ["contactName", "contactEmail"],
    ["contactEmail", "contactPhoneNumber"],
    ["contactEmail", "contactTitle"],
    ["contactTitle", "contactPhoneNumber"],
    ["contactName", "contactTitle"],
  ]
);

// Change password
export const passwordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(message),
  newPassword: Yup.string()
    .required(message)
    .matches(
      passwordRegex,
      "Password should be of minimum 8 characters having at least 1 number and 1 special character."
    ),
  confirmPassword: Yup.string()
    .required(message)
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export const keycontactsSchema = Yup.object().shape(
  {
    contactName: Yup.string().when(
      ["contactTitle", "contactEmail", "contactPhoneNumber"],
      {
        is: (field2, field3, field4) =>
          field2 !== undefined || field3 !== undefined || field4 !== undefined,
        then: (schema) => schema.required(message),
        otherwise: (schema) => schema.notRequired(),
      }
    ),

    contactEmail: Yup.string().when(["contactTitle", "contactPhoneNumber"], {
      is: (field2, field3) => field2 !== undefined || field3 !== undefined,
      then: (schema) => schema.required(message).email(emailMessage),
      otherwise: (schema) => schema.notRequired(),
    }),

    contactPhoneNumber: Yup.string().when(
      ["contactTitle", "contactName", "contactEmail"],
      {
        is: (field2, field3) => field2 !== undefined || field3 !== undefined,
        then: (schema) =>
          schema
            .required(message)
            .matches(phoneRegExp, "Phone number is not valid"),
      }
    ),

    contactTitle: Yup.string().when(
      ["contactName", "contactPhoneNumber", "contactEmail"],
      {
        is: (field2, field3) => field2 !== undefined || field3 !== undefined,
        then: (schema) => schema.required(message),
      }
    ),
  },
  [
    ["contactName", "contactPhoneNumber"],
    ["contactName", "contactEmail"],
    ["contactEmail", "contactPhoneNumber"],
    ["contactEmail", "contactTitle"],
    ["contactTitle", "contactPhoneNumber"],
    ["contactName", "contactTitle"],
  ]
);

// Forgot Password
export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email(emailMessage).required(message),
});

// Change password after forget password
export const enterNewPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(message)
    .matches(
      passwordRegex,
      "Password should be of minimum 8 characters having at least 1 number and 1 special character."
    ),
  confirmPassword: Yup.string()
    .required(message)
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export const headOfficeContactSchema = Yup.object().shape({
  name: Yup.string().required(message),
  title: Yup.string().required(message),
  email: Yup.string().email(emailMessage).required(message),
  phone: Yup.string()
    .required(message)
    .matches(phoneRegExp, "Phone number is not valid"),
});

// export const keyContactSchema = Yup.object().shape({
//   email: Yup.string().email(emailMessage).required(message),
//   phone: Yup.string().required(message)
//     .matches(phoneRegExp, 'Phone number is not valid')

// });

// Change password after forget password
export const claimSchema = Yup.object().shape({
  comments: Yup.string().required(message),
});

// Change password after forget password
export const claimForwardSchema = Yup.object().shape({
  managerId: Yup.string().required(message),
  managerId: Yup.string().required(message),
});

export const instrumentScehema = Yup.object().shape({
  date: Yup.string().required(message),
  assetNo: Yup.string().required(message),
  modelNo: Yup.string().required(message),
  modelName: Yup.string().required(message),
  serialNo: Yup.string().required(message),
  nextCalibration: Yup.string(),
  currentProject: Yup.string().required(message),
  accountable: Yup.string().required(message),
  damaged: Yup.string().required(message),
  notes: Yup.string().required(message),
});

export const equipmentScehema = Yup.object().shape({
  assetNo: Yup.string().required(message),
  modelNo: Yup.string().required(message),
  modelName: Yup.string().required(message),
  serialNo: Yup.string().required(message),
  nextCalibration: Yup.string().required(message),
  // date: Yup.string().required(message),
});

export const manageFleetDataScehema = Yup.object().shape({
  assetNo: Yup.string().required(message),
  modelNo: Yup.string().required(message),
  make: Yup.string().required(message),
  year: Yup.string().required(message),
  licensePlate: Yup.string().required(message),
  vin: Yup.string().required(message),
  registrationRenewal: Yup.string().required(message),
  inlineOrRentel: Yup.string().required(message),
  lastProject: Yup.string().required(message),
  lastServiced: Yup.string().required(message),
  lastServiceDate: Yup.string().required(message),
  // date: Yup.string().required(message),
});

export const fleetTrackingScehema = Yup.object().shape({
  assetNo: Yup.string().required(message),
  fireExSerialNo: Yup.string().required(message),
  fireExInspectionDate: Yup.string().required(message),
  currentProject: Yup.string().required(message),
  siteLead: Yup.string(),
  closestTown: Yup.string().required(message),
  nextService: Yup.string().required(message),
  firstAidKits: Yup.string().required(message),
  spillKits: Yup.string().required(message),
  emergeNycKit: Yup.string().required(message),
  returnDate: Yup.string(),
  mikisewDecals: Yup.string().required(message),
  fossCard: Yup.string().required(message),
  notes: Yup.string().required(message),
});

export const claimReimbursementListScehema = Yup.object().shape({
  date: Yup.string().required(message),
  description: Yup.string().required(message),
  isBillable: Yup.string().required(message),
  isWorkwearExpense: Yup.string().required(message),
  department: Yup.string().required(message),
  project: Yup.string().required(message),
  amount: Yup.string().required(message),
  approvingManagerName: Yup.string().required(message),
});
